//SCSS
import '../scss/main.scss';
//Vendor-scripts
import './vendor/slick.min.js'
import './vendor/jquery.fancybox.min.js'
import './vendor/jQuery.maskedinput.min.js'
import './vendor/jquery-ui.min.js'
// import './vendor/jquery-mousewheel.min.js'
// import './vendor/jquery.mCustomScrollbar.concat.min.js'
//Bootstrap
// import 'bootstrap';

//HTML
import '../../index.html';



$(document).ready(function () {
    $('.projects__slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,

        dots: false,
        nextArrow: $('.projects-next'),
        prevArrow: $('.projects-prev'),

        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]    
    });

    $('[data-burger]').on('click', function(){
        let $this = $(this),
        $header = $('.header'),
        $list = $('.header__mobile');

        if($this.hasClass('_active')) {
            $this.removeClass('_active');
            $header.removeClass('_active');
            $list.removeClass('_active');
            $('.header__mobile-overlay').fadeOut(200);           
        } else {
            $this.addClass('_active');
            $header.addClass('_active');
            $list.addClass('_active'); 
            $('.header__mobile-overlay').fadeIn(200);           
        }
    });

    $('[data-fancybox="gallery"]').fancybox({
        infobar : false,
        buttons : false,
        afterLoad : function( instance, current ) {
          if ( instance.group.length > 1 && current.$content ) {
            current.$content.append('<a data-fancybox-next class="button-next custom-nav" href="javascript:;"><svg><use xlink:href="/assets/img/sprite..svg#arrow-left"></svg></a><a data-fancybox-previous class="button-previous custom-nav" href="javascript:;"><svg><use xlink:href="/assets/img/sprite..svg#arrow-left"></svg></a>');
          }
          
        //   current.$content.append('<a data-fancybox-close class="button-close" href="javascript:;">×</a>');
        }
      })
});